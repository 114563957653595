footer{
    background-color: rgb(50, 55, 58);
    border-radius: 10px 10px 0px 0px;
    margin-top: 100px;
    padding: 20px 20px 10px 20px;
    color: whitesmoke;
    font-family: 'Built Titling Regular';
}

.hours_location{
    display: flex;
    justify-content: space-evenly;
    line-height: 1.5em;
    margin-bottom: 20px;
}

.hours td{
    padding: 0px 10px;
}

iframe{
    width: 250px;
    height: 187px;
    border: 0;
}

.otr{
    font-size: 8px;
    font-family: Arial, sans-serif;
    padding-top: 15px;
    margin-bottom: -10px;
}

.otr > a{
    text-decoration: none;
}

.otr > a:link{
    color:whitesmoke;
}

.otr > a:visited{
    color:whitesmoke;
}

/* @media (max-width: 900px){
    .hours_location{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .hours td{
        padding: 0px 10px;
    }

    iframe{
        margin-top: 20px;
        width: 250px;
        height: 187px;
        border: 0;
    }
    
} */

@media (max-width: 576px){
    .hours_location{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .hours td{
        padding: 0px 10px;
    }

    iframe{
        margin-top: 20px;
        width: 250px;
        height: 187px;
        border: 0;
    }
}

@media (max-width: 1200px){
    footer{
        border-radius: 0px;
    }
}