@import "./components/Header.css";
@import "./components/Informations.css";
@import "./components/Photos.css";
@import "./components/Reviews.css";
@import "./components/Footer.css";


@font-face{
  font-family: 'Built Titling Regular';
  src: local('Built Titling Regular'), url(../../public/fonts/built-titling-rg.ttf) format('truetype');
}

*{
  margin: 0;
  padding: 0;
}

body{
  margin-left: auto;
  margin-right: auto;
  width: 50vw;
  text-align: center;
  padding-top: 5vh;
}

@media (max-width: 1200px){
  body{
    width: 100vw;
    padding-top: 0;
  }
  
}
