.photos_carousel{
    margin-top: 100px;
}

.photos_carousel img{
    width: 600px;
}


@media (max-width: 900px){
    .photos_carousel img{
        width: 400px;
    }
    
}