.card_review{
    font-family: Arial, sans-serif;
    font-size: 13px;
    text-align: left;
    width: 32vw;
    min-height: 130px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    margin-bottom: 50px;
}

.card_review a{
    text-decoration: none;
}

.pp_user_icon {
    width: 30px;

}

.pp_name{
    display: flex;
    color: black;
}

.name_reviews{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    line-height: 1.3em;
}

.name_reviews a:visited{
    color: black;
}

.pseudo{
    font-weight: bold;
    color: black; 
}

.pseudo a{
    font-weight: bold;
    color: black;
}

.pp_name a:visited{
    color: black;
}

.pp_name a:hover{
    text-decoration: underline;
}

.reviews{
    color: #70757a;
}

.review_text{
    line-height: 18px;
}

.stars{
    margin-top: 7px;
    margin-bottom: 7px;
    width: 75px;
}

@media (max-width: 1200px){
    .card_review{
        width: 75vw;
        min-height: 202px;
    }
    
}
