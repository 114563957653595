.location_description{
    margin-top: 100px;
    line-height: 3em;
    font-family: 'Built Titling Regular';
}

.location{
    font-size: 2em;
}

.description{
    font-size: 1.4em;
}

.join_me{
    margin: 0px 20px;
    display: flex;
    justify-content: space-evenly;
    font-size: 1.4em;
}

.join_me a{
    text-decoration: none;
    color: black;
}

.call_me p, .email_me p{
    border: 1px solid black;
    border-radius: 10px;
    padding: 5px 20px;
}

.call_me p:hover{
    color: blue;
    border: 1px solid blue;
}

.email_me p:hover{
    color: red;
    border: 1px solid red;
}


@media (max-width: 1200px){
    .location_description{
        position: relative;
        margin-top: 39vw;
    }
} 

@media (max-width: 966px){
    .location_description{
        position: relative;
        margin-top: 40vw;
    }
    
}
@media (max-width: 733px){
    .location_description{
        position: relative;
        margin-top: 42vw;
    }
    
}

@media (max-width: 500px){
    .location_description{
        position: relative;
        margin-top: 47vw;
    }
    
}




  
