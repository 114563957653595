header{
    display: flex;
    justify-content: space-between;
    height: 30vh;
    background-image: url("../../../public/images/Background_Header_3.jpg");
    background-position: 50% 90%;
    background-size: cover;
    border-radius: 10px;
    text-align: left;
}

.appointment_button{
    width: 220px;
    margin-top: auto;
    margin-bottom: auto;
}

.book_appointment{
    background-color: transparent;
    border: none;
    opacity: 1;
}

.book_appointment img:hover{
    opacity: 0.80;
}

.book_appointment img{
    min-width: 120px;
    width: 10.5vw;
    padding-left: 10px;
}

.logo{
    width: 20vh;
    margin-top: auto;
    margin-bottom: auto;
    opacity: 0.85;
}

.social_network{
    width: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    opacity: 1;
}

.social_network img:hover{
    opacity: 0.80;
}

.facebook img{
    width: 40px;
    padding: 5px 10px 5px 0px;
}

.instagram img{
    width: 40px;
    padding: 5px 10px 5px 0px;
}

@media (max-width: 1200px){

    header{
        border-radius: 0;
        display: block;
        height: 40vw;
        width: auto;
        text-align: center;
    }

    .appointment_button{
        width: 100%;
        position: absolute;
        padding-top: 45%;
    }

    .book_appointment img{
        width: 50vw;
        padding-left: 0;
    }

    .logo{
        width: 30%;
        opacity: 0.85;
        position: relative;
        top: 10%;
    }

    .social_network{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        margin-top: 37vw;
        opacity: 1;
    }

    .facebook img{
        width: 50px;
        padding: 0;
    }
    
    .instagram img{
        width: 50px;
        padding: 0;
    }
    
}